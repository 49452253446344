<template>
    <div class="content-wrapper">
        <PageHeader :screenName="pageTitle" :linkItems="linkItems" />

        <main class="card p-2" :class="{ 'opacity-50': loadingData }">
            <section class="infos">
                <b-row>
                    <b-col lg="6" md="6" sm="12" class="mb-2" v-for="informacao in informacoes" :key="informacao._id">
                        <label class="font-weight-bold mb-0 font-size-15">{{ informacao.label }}</label>
                        <p class="font-size-14 p-0 m-0">{{ informacao.value }}</p>
                    </b-col>
                </b-row>
                <hr />
                <b-row class="mt-2 d-flex flex-column">
                    <b-col cols="12">
                        <label class="font-weight-bold mb-1 font-size-15">Cidades Vinculadas</label>
                    </b-col>
                    <b-col cols="12" v-if="cidades.length > 0" class="d-flex flex-wrap">
                        <div v-for="cidade in cidades" :key="cidade.id_cidade" class="rounded cities custom">
                            {{ cidade.nome }}
                        </div>
                    </b-col>
                    <b-col cols="12" v-else class="text-danger">-</b-col>
                </b-row>
            </section>
        </main>
    </div>
</template>

<script>
import { BRow, BCol, BButton } from 'bootstrap-vue';

import { getIdUnidade, getAcronymUnidade } from '@core/utils/store/getStore';
import { setIdUnidade, setAcronymUnidade, setListFilter } from "@core/utils/store/setStore";
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue';
import helpers from '@/helpers';

export default {
    title: 'Detalhes unidade',

    components: {
        PageHeader,
        BRow, BCol, BButton
    },

    data() {
        return {
            pageTitle: 'Visualizar Unidade',
            linkItems: [
                { name: 'Unidades Operacionais', routeName: 'unidade-list' },
                { name: '', active: true }
            ],
            loadingData: true,
            informacoes: [],
            cidades: [],
        }
    },

    mounted() {
        if ((getIdUnidade() === null || typeof getIdUnidade() == 'undefined') && typeof this.$route.params.id_unidade == "undefined") {
            return this.redirectToList();
        } else if (typeof this.$route.params.id_unidade != "undefined" && this.$route.params.id_unidade !== getIdUnidade()) {
            setIdUnidade(this.$route.params.id_unidade);
            setAcronymUnidade(this.$route.params.acronym);
        }

        this.linkItems[1].name = getAcronymUnidade();
        this.pageTitle = getAcronymUnidade();

        this.loadDataUnidade();
    },

    methods: {
        loadDataUnidade() {
            this.$http.get(this.$api.unidadeParameter(getIdUnidade())).then(({ data }) => {
                
                setListFilter({
                    route: this.$router.currentRoute.name,
                    departamento:
                    {
                        id_departamento: data.regiao.id_departamento,
                        descricao: data.regiao.departamento.descricao
                    }
                });

                this.cidades = data.cidade

                const cep = helpers.adicionarMascaraCep(data.cep)
                this.informacoes = [
                    { label: "Tipo", value: data.tipo, },
                    { label: "Departamento Regional", value: data.regiao.departamento.descricao, },
                    { label: "Região", value: data.regiao.descricao, },
                    { label: "Nome da Unidade Operacional", value: data.descricao, },
                    { label: "CNPJ da Unidade Operacional", value: helpers.adicionarMascaraCnpj(data.cnpj), },
                    { label: "Código SMD da Unidade Operacional", value: data.codigo_smd, },
                    { label: "Código para integração externa", value: data.codigo_smd_integracao, },
                    { label: "CEP", value: cep.length > 0 ? cep : '-', },
                    { label: "Logadouro", value: data.endereco ?? '-', },
                    { label: "Número", value: data.numero ?? '-', },
                    { label: "Ponto de Referência", value: data.ponto_referencia ?? '-', },
                    { label: "Bairro", value: data.bairro ?? '-', },
                    { label: "UF", value: data.uf_cidade_local_fisico, },
                    { label: "Cidade", value: data.nome_cidade_local_fisico, },
                    { label: "Responsável por essa Unidade Operacional", value: data.contato?.nome_responsavel ?? '-', },
                    { label: "E-mail", value: data.contato?.email_responsavel ?? '-', },
                ]

                this.loadingData = false;
            }).catch(() => {
                this.redirectToList();
            })
        },
        redirectToList() {
            this.$router.push({ name: 'unidade-list' });
        },
    }
}
</script>
<style scoped>
.cities {
    background-color: #2772C0;
    color: #ffffff;
}

.custom {
    padding: 5px 10px;
    margin-right: 10px;
    margin-bottom: 5px;
    font-weight: 400;
}
</style>
